.index-menu {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;

  .menu a {
    text-transform: uppercase;
    color: $body-font-color;
    transition: color 0.2s ease-in;

    &:hover {
      color: $primary-color;
    }
  }
}

.index-menu2 {
  margin-top:20px;
  height: 10vh;
  display: flex;
  // align-items: center;
  justify-content: center;

  .menu a {
    text-transform: uppercase;
    color: $body-font-color;
    transition: color 0.2s ease-in;

    &:hover {
      color: $primary-color;
    }
  }


}

 @media (max-width:768px){
    .index-menu2 {
    height: 20vh;
     justify-content: center;
    }
  }
